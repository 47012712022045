import React, { useState, useEffect } from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col, Select, InputNumber, Button, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import FileUploader from "components/FileUploader";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const EditProductForm = ({ initialRecord }) => {
  const [fileList, setFileList] = useState([]);
  const [productSize, setProductSize] = useState("singleSize");
  const iuProps = useSelector((state) => state.productR);
  const teamProps = useSelector((state) => state.categoryR);
  const { ShowModalEdit, addingProduct, initialValues, SubCategoryList } = iuProps;
  const { Category } = teamProps;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchCategoryList = () => {
    dispatch(DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList"));
    if (initialValues && initialValues["category"]) {
      dispatch(DataGetAction("getSubCategory", { catId: initialValues.category }, "fetchingSubCategoryList"));
    }
  };
  useEffect(fetchCategoryList, [initialValues]);

  useEffect(() => {
    if (SubCategoryList?.length > 0 && initialValues?.subCategory) {
      form.setFieldsValue({ subCategory: initialValues.subCategory });
    }
  }, [SubCategoryList, initialValues, form]);

  const onCategoryChange = (_id) => {
    dispatch(DataGetAction("getSubCategory", { catId: _id }, "fetchingSubCategoryList"));
  };

  useEffect(() => {
    if (initialValues && initialValues.images && initialValues.images.length > 0) {
      setFileList(initialValues.images);
    }
  }, [initialValues]);

  return (
    <div>
      <FormModal
        modalTitle={"Update Product"}
        submitButtonText={"Update"}
        cancelButtonText="Cancel"
        apiMethod="POST"
        apiName="addProduct"
        isShowButton={false}
        editApiMethod="PUT"
        editApiName="editProduct"
        apiLoaderName="addingProduct"
        confirmLoading={addingProduct}
        ModalType="EditProduct_Modal"
        visible={ShowModalEdit}
        initialValues={initialValues}
        initialRecord={initialRecord}
        extraFieldName="images"
        extraFieldValue={fileList}
      >
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="SKU" name="sku" rules={[{ required: true, message: "Please input sku!" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Product Name" name="productName" rules={[{ required: true, message: "Please input your Product Name!" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please choose your parent category!",
                },
              ]}
            >
              <Select
                onChange={(data) => {
                  onCategoryChange(data);
                }}
              >
                {Category && Category.map((Item) => <Select.Option value={Item._id}>{Item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Sub Category" name="subCategory" rules={[{ required: true, message: "Please choose your sub category!" }]}>
              <Select>{SubCategoryList && SubCategoryList.map((Item) => <Select.Option value={Item._id}>{Item.name}</Select.Option>)}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Estimated Time"
              name="estimatedTime"
              rules={[
                {
                  required: true,
                  message: "Please input your Estimated Time!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Size" name="productSize" initialValue="singleSize">
              <Select
                onChange={(value) => {
                  setProductSize(value);
                }}
              >
                <Select.Option value="singleSize">Single Size</Select.Option>
                <Select.Option value="multiSize">Multiple Size</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row></Row>
        {productSize === "singleSize" ? (
          <Row className="gx-flex-row">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item label="Price" name="price" rules={[{ required: true, message: "Please input your Price!" }]}>
                <InputNumber className="gx-w-100" min={0} precision={2} step={0.1} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row className="gx-flex-row">
            <Col lg={8} md={8} sm={8} xs={8}>
              <Form.Item label="Small Size Price" name="smallSizePrice" rules={[{ required: true, message: "Please input your Price!" }]}>
                <InputNumber className="gx-w-100" min={0} precision={2} step={0.1} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <Form.Item label="Medium Size Price" name="mediumSizePrice" rules={[{ required: true, message: "Please input your Price!" }]}>
                <InputNumber className="gx-w-100" min={0} precision={2} step={0.1} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <Form.Item label="Large Size Price" name="largeSizePrice" rules={[{ required: true, message: "Please input your Price!" }]}>
                <InputNumber className="gx-w-100" min={0} precision={2} step={0.1} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* Hide Price Checkbox */}
        <Row className="gx-flex-row">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="hidePrice" valuePropName="checked" initialValue={initialRecord?.hidePrice || false}>
              <Checkbox>Hide Price</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-flex-row">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input your Description!" }]}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.List name="links">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div className="gx-d-flex">
                      <div className="gx-w-100">
                        <Form.Item
                          key={key}
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "title is required",
                            },
                          ]}
                        >
                          <Input placeholder="Add Title" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "link"]}
                          rules={[
                            {
                              required: true,
                              message: "link is required",
                            },
                          ]}
                        >
                          <Input placeholder="Add Link" />
                        </Form.Item>
                      </div>
                      <span className="gx-pl-2" style={{ width: "30px", marginTop: "-10px" }}>
                        <MinusCircleOutlined style={{ color: "red" }} onClick={() => remove(name)} />
                      </span>
                    </div>
                  ))}
                  <Form.Item>
                    <Button className="gx-py-0" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Links
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="tasksDocument" label="Attachment" rules={[{ required: false, message: "Please enter document" }]}>
              <FileUploader updateAssigned={setFileList} myAssigned={fileList} setFileList={setFileList} multiple={true} />
            </Form.Item>
          </Col>
        </Row>
      </FormModal>
    </div>
  );
};

export default EditProductForm;
